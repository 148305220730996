<template>
  <div>
    <v-card
      flat
      color="transparent"
      class="overflow-hidden"
      v-if="$root.mobile"
    >
      <v-app-bar
        v-if="false"
        absolute
        flat
        hide-on-scroll
        height="80"
        class="ma-0 pa-0"
        scroll-target="#feed"
        :style="
          showsearch
            ? 'z-index:10001; background-color:transparent'
            : 'background-color:rgb(238, 240, 248)'
        "
        :dark="showsearch"
      >
        <v-text-field
          filled
          class="ma-0"
          rounded
          hide-details
          v-model="search"
          label="Search Doctory"
          single-line
          @click="showsearch = true"
        >
          <template v-slot:prepend-inner>
            <v-img
              src="/media/logos/logo.png"
              height="27"
              contain
              width="27"
              class="pushx"
              :class="$root.lang === 'ar' ? 'mr-4' : 'ml-4'"
            />
          </template>
          <template v-slot:append>
            <router-link to="/profile" v-slot="{ href, navigate, isActive }">
              <v-btn
                @click="navigate"
                :href="href"
                icon
                width="45"
                height="45"
                class="mx-0"
                :class="isActive ? 'white' : 'amber darken-1'"
              >
                <v-avatar v-if="$user.getCurrentUser()" size="45">
                  <v-img
                    v-if="$user.getCurrentUser().avatar != null"
                    :src="
                      $root.apiURL +
                        'assets/' +
                        $user.getCurrentUser().avatar.private_hash
                    "
                  ></v-img>
                  <unicon
                    v-else
                    name="user-circle"
                    :fill="isActive ? '#FFB300' : '#FFFFFF'"
                    height="22px"
                    width="22px"
                  ></unicon>
                </v-avatar>
              </v-btn>
            </router-link>
          </template>
        </v-text-field>
      </v-app-bar>
      <v-sheet
        v-if="!isAddopen"
        id="feed"
        class="overflow-y-auto transparent hidescroll"
        height="calc(100vh - 112px)"
      >
        <v-container class="px-0 overflow-x: hidden;" v-scroll:#feed="onScroll">
          <!--<PulltoRefresh
            v-if="offsetTop == 0"
            ref="refresh"
            @dorefresh="refreshaction"
          />-->
          <div class="text-primary font-weight-bolder px-1 py-2 text-h4">
            {{ $t("GENERAL.welcomeonly") }}
            {{ $user.getCurrentUser().first_name }}
          </div>
          <v-row>
            <v-col :class="$root.lang === 'ar' ? 'ml-2 pl-0' : 'mr-2 pr-0'">
              <div
                @click="
                  addDialog = false;
                  openAddAppointment();
                "
                class="d-flex align-center light-blue lighten-5 rounded-lg pa-4 primary--text"
                style="min-height:90px;font-size:1.4rem;overflow: hidden;    position: relative;"
              >
                <div>{{ $t("DASHBOARD.PATIENT.book.title") }}</div>
                <unicon
                  :style="
                    $root.lang === 'ar' ? 'left: -20px;' : 'right: -20px;'
                  "
                  style="position: absolute;transform: rotate(10deg); transform: rotate(10deg);opacity: 0.2;"
                  height="90"
                  width="90"
                  name="calender"
                  fill="#0971CE"
                ></unicon>
              </div>
            </v-col>
            <v-col :class="$root.lang === 'ar' ? 'mr-2 pr-0' : 'ml-2 pl-0'">
              <div
                @click="
                  addDialog = false;
                  showaddQ = true;
                "
                class="d-flex align-center light-blue lighten-4 rounded-lg pa-4 primary--text"
                style="min-height:90px;font-size:1.4rem; overflow:hidden; position:relative"
              >
                <div>{{ $t("DASHBOARD.PATIENT.advice.action") }}</div>
                <unicon
                  :style="
                    $root.lang === 'ar' ? 'left: -20px;' : 'right: -20px;'
                  "
                  style="position: absolute;transform: rotate(15deg); transform: rotate(10deg);opacity: 0.2;"
                  height="90"
                  width="90"
                  name="question-circle"
                  fill="#0971CE"
                ></unicon>
              </div>
            </v-col>
          </v-row>
          <!--upcoming -->
          <v-expand-transition>
            <v-row
              v-if="
                nextapp != null &&
                  nextapp.length > 0 &&
                  nextapp[0].status === 'progress'
              "
              class="ma-0 pa-0"
              style="    overflow: hidden;"
            >
              <v-col class="pa-0">
                <div
                  class="mb-2 listitem lglistitem rounded-lg"
                  style="min-height: 100px"
                >
                  <div class="p-1 px-6 flex-grow-1">
                    <h4 class="text-primary font-weight-bolder">
                      {{ $t("DASHBOARD.PATIENT.next.title") }}
                      {{ shownextapptime(nextapp[0].date_time) }}
                    </h4>

                    <v-btn
                      v-if="nextapp[0].type == 'video'"
                      color="primary"
                      class="mt-2"
                      style="margin-left: -15px; margin-right: -15px"
                      text
                      :to="`appointment/${nextapp[0].id}`"
                      >{{ $t("DASHBOARD.PATIENT.next.action") }}</v-btn
                    >
                    <v-btn
                      v-else
                      color="primary"
                      class="mt-1 mb-0"
                      disabled
                      style="margin-left: -15px; margin-right: -15px"
                      text
                      >{{ $t("DASHBOARD.PATIENT.next.doctoriswaiting") }}</v-btn
                    >
                  </div>
                  <lottie-animation
                    :animationData="
                      require('@/assets/animation/notification.json')
                    "
                    :loop="true"
                    :style="
                      $root.lang === 'ar' ? 'left: -35px;' : 'right: -35px;'
                    "
                    style="
    position: absolute;
    
    height: 100%;
    transform: rotate(15deg);
"
                  />
                </div>
              </v-col>
            </v-row>
          </v-expand-transition>
          <!-- end upcoming -->
          <!--articals & advice -->
          <Feed />
          <!-- end articles and advice -->
        </v-container>
      </v-sheet>
      <v-scroll-y-reverse-transition>
        <v-btn
          v-if="!isAddopen"
          v-show="showAddBtn"
          :style="$root.lang === 'ar' ? 'left: 10px;' : 'right: 10px;'"
          style="z-index: 999; position: fixed; bottom: calc(env(Safe-area-inset-bottom, 40px) + 30px)"
          fab
          elevation="0"
          color="primary"
          dark
          @click="
            rotate();
            addDialog = !addDialog;
          "
        >
          <v-icon ref="addicon" class="" color="white">mdi-plus</v-icon>
        </v-btn>
      </v-scroll-y-reverse-transition>
      <v-bottom-sheet
        hide-overlay
        v-if="$vuetify.breakpoint.mobile"
        v-model="addDialog"
      >
        <v-card class="px-5 py-4 rounded-lg" elevation="0">
          <v-card
            @click="
              addDialog = false;
              rotate();
              openAddAppointment();
            "
            class="light-blue lighten-5 rounded-lg my-3"
            flat
            style="
              min-height: 10vh;
              display: flex;
              flex-direction: column;
              justify-content: center;
              overflow: hidden;
            "
          >
            <v-card-text class="px-4 primary--text" style="font-size:1.2rem">
              {{ $t("DASHBOARD.PATIENT.book.title") }}
            </v-card-text>

            <lottie-animation
              :animationData="require('@/assets/animation/next_app.json')"
              :loop="true"
              :style="$root.lang === 'ar' ? 'left: -50px;' : 'right: -50px;'"
              style="
                position: absolute;
                top: -10px;
                    height: 15vh;
                transform: rotate(10deg);
              "
              :class="'cardanime'"
            />
          </v-card>
          <v-card
            @click="
              addDialog = false;
              rotate();
              showaddQ = true;
            "
            class="light-blue lighten-4 rounded-lg"
            flat
            style="
              min-height: 10vh;
              display: flex;
              flex-direction: column;
              justify-content: center;
              overflow: hidden;
            "
          >
            <v-card-text class="px-4 primary--text" style="font-size:1.2rem">
              {{ $t("DASHBOARD.PATIENT.advice.action") }}
            </v-card-text>

            <lottie-animation
              :animationData="require('@/assets/animation/questions.json')"
              :loop="true"
              :style="$root.lang === 'ar' ? 'left: -40px;' : 'right: -40px;'"
              style="position: absolute;top: -12px;height: 15vh;"
              :class="'cardanime'"
            />
          </v-card>
        </v-card>
      </v-bottom-sheet>

      <v-dialog
        v-if="false"
        v-model="showsearch"
        fullscreen
        transition="dialog-bottom-transition"
        class="transparent"
        style="z-index: 1000"
      >
        <div
          style="
          width: 100%;
          height: 100vh;
          background-color: rgba(0, 0, 0, 0.95);
          display: flex;
          padding-bottom: 140px;
          flex-direction: column;
          justify-content: end;
        "
          class="px-4"
        >
          search here
        </div>
      </v-dialog>
      <v-bottom-sheet
        style="z-index: 1000"
        scrollable
        v-if="$vuetify.breakpoint.mobile"
        v-model="showfeeddetails"
      >
        <v-card v-if="selectedFeedItem" class="" elevation="0">
          <v-app-bar
            v-if="selectedFeedItem.cover"
            absolute
            elevation="0"
            class="white--text px-4 transparent"
          >
            <v-toolbar-title>
              {{ selectedFeedItem.content.title }}
            </v-toolbar-title>
            <v-spacer />
            <v-btn icon dark color="white" @click="showfeeddetails = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-app-bar>
          <v-app-bar v-else elevation="0" class="primary--text px-4 mb-2 white">
            <v-toolbar-title>
              {{ selectedFeedItem.content.title }}
            </v-toolbar-title>
            <v-spacer />
            <v-btn icon dark color="primary" @click="showfeeddetails = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-app-bar>
          <v-img
            :gradient="`to bottom , #0971ce , rgba(25,32,72,.5)`"
            v-if="selectedFeedItem.cover"
            height="140"
            class="rounded mb-2"
            :src="asseturl + selectedFeedItem.cover.private_hash"
          ></v-img>
          <div class="d-flex justify-space-between align-center px-4 mb-4">
            <div>
              <v-avatar class="pushx" size="40" color="primary">
                <v-img
                  v-if="selectedFeedItem.owner && selectedFeedItem.owner.avatar"
                  :src="asseturl + selectedFeedItem.owner.avatar.private_hash"
                />
                <v-img
                  height="20"
                  width="20"
                  contain
                  v-else
                  src="/media/logos/logo-white.png"
                />
              </v-avatar>
              <span v-if="selectedFeedItem.owner"
                >{{ selectedFeedItem.owner.first_name }}
                {{ selectedFeedItem.owner.last_name }}</span
              >
              <span v-else>Doctory Doctor</span>
            </div>
            <div @click="likeaction(selectedFeedItem)">
              <v-icon
                v-if="selectedFeedItem.like"
                size="18"
                class="mx-1"
                color="red"
                >mdi-heart</v-icon
              >
              <v-icon v-else size="18" class="mx-1" color="red"
                >mdi-heart-outline</v-icon
              >
              <span v-if="selectedFeedItem.likes">{{
                selectedFeedItem.likes
              }}</span>
            </div>
          </div>
          <v-card-text
            style="font-size:1.2rem"
            v-html="selectedFeedItem.content.content"
          >
          </v-card-text>
        </v-card>
      </v-bottom-sheet>
    </v-card>
    <v-card flat color="transparent" v-else>
      <div v-if="isComplete">
        <div class="row">
          <div class="col-lg-4 col-xl-4 col-sm-6 py-0">
            <!--begin::Engage next appointment alert -->

            <v-card
              class="light-blue lighten-5 rounded-lg"
              flat
              style="
              min-height: 20vh;
              display: flex;
              flex-direction: column;
              justify-content: center;
              overflow: hidden;
            "
            >
              <v-card-text class="">
                <v-card-title
                  class="primary--text font-weight-bolder font-size-h4 px-4 pb-1"
                >
                  {{ $t("DASHBOARD.PATIENT.next.title") }}
                </v-card-title>
                <template v-if="nextapp">
                  <div class="px-4 blue-grey--text font-weight-bold">
                    <span v-if="nextapp.length > 0">
                      {{
                        (nextapp[0].date_time + "Z")
                          | moment("timezone", getTimeZone(), "from", "now")
                      }}
                    </span>
                    <span v-else>
                      {{ $t("DASHBOARD.PATIENT.next.noavail") }}
                    </span>
                  </div>
                </template>
                <div v-else class="px-4 blue-grey--text font-weight-bold">
                  {{ $t("DASHBOARD.PATIENT.next.loading") }}
                </div>
                <v-btn
                  v-if="
                    nextapp != null &&
                      nextapp.length > 0 &&
                      nextapp[0].status == 'progress'
                  "
                  color="primary"
                  class="mt-2"
                  text
                  >{{ $t("DASHBOARD.PATIENT.next.action") }}</v-btn
                >
              </v-card-text>

              <lottie-animation
                :animationData="require('@/assets/animation/next_app.json')"
                :loop="true"
                :style="$root.lang === 'ar' ? 'left: -50px;' : 'right: -50px;'"
                style="
                position: absolute;
                
                top: -20px;
                transform: rotate(10deg);
              "
                :class="'cardanime'"
              />
            </v-card>
            <!--end::Engage next appointment alert -->
          </div>
          <div class="col-lg-4 col-xl-4 col-sm-6 py-0">
            <v-card
              class="light-blue lighten-4 rounded-lg"
              flat
              style="
              min-height: 20vh;
              display: flex;
              flex-direction: column;
              justify-content: center;
              overflow: hidden;
            "
            >
              <v-card-text class="">
                <v-card-title
                  class="primary--text font-weight-bolder font-size-h4 px-4 pb-1"
                >
                  {{ $t("DASHBOARD.PATIENT.advice.title") }}
                </v-card-title>
                <div class="px-4 blue-grey--text font-weight-bold">
                  {{ $t("DASHBOARD.PATIENT.advice.prompt") }}
                </div>
                <v-btn
                  color="primary"
                  class="mt-2"
                  text
                  @click="showaddQ = !showaddQ"
                  >{{ $t("DASHBOARD.PATIENT.advice.action") }}</v-btn
                >
              </v-card-text>

              <lottie-animation
                :animationData="require('@/assets/animation/questions.json')"
                :loop="true"
                :style="$root.lang === 'ar' ? 'left: -20px;' : 'right: -20px;'"
                style="position: absolute;  top: -20px"
                :class="'cardanime'"
              />
            </v-card>
            <!--begin::Engage question -->

            <!--end::Engage next qustion-->
          </div>
          <div class="col-lg-4 col-xl-4 col-sm-12 py-0">
            <v-card
              class="light-blue lighten-3 rounded-lg"
              flat
              style="
              min-height: 20vh;
              display: flex;
              flex-direction: column;
              justify-content: center;
              overflow: hidden;
            "
            >
              <v-card-text class="">
                <v-card-title
                  class="primary--text font-weight-bolder font-size-h4 px-4 pb-1"
                >
                  {{ $t("DASHBOARD.PATIENT.book.title") }}
                </v-card-title>
                <div class="px-4 blue-grey--text font-weight-bold">
                  {{ $t("DASHBOARD.PATIENT.book.prompt") }}
                </div>
                <v-btn
                  color="primary"
                  class="mt-2"
                  text
                  @click="openAddAppointment()"
                  >{{ $t("DASHBOARD.PATIENT.book.action") }}</v-btn
                >
              </v-card-text>

              <lottie-animation
                :animationData="require('@/assets/animation/book.json')"
                :loop="true"
                :style="$root.lang === 'ar' ? 'left: -50px;' : 'right: -50px;'"
                style="
                position: absolute;
                top: -20px;
                transform: rotate(10deg);
              "
                :class="'cardanime'"
              />
            </v-card>
            <!--begin::Engage question -->

            <!--end::Engage next qustion-->
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 py-0">
            <h4 class="text-primary font-weight-bolder px-1 py-2">
              {{ $t("DASHBOARD.PATIENT.upcoming.title") }}
            </h4>
            <AppointmentUpcoming
              v-if="nextapp != null && nextapp.length > 0"
              :reload="reloadupcoming"
              :skltoload="1"
            />
            <div
              v-else
              style="width: 100%; display: flex; justify-content: center"
              class="mt-3"
            >
              <v-btn
                color="primary"
                @click="openAddAppointment()"
                rounded
                elevation="0"
                large
              >
                {{ $t("DASHBOARD.PATIENT.upcoming.action") }}
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </v-card>
    <NewAppointment @didclose="isAddopen = false" ref="newApp" />
    <AddQ
      style="z-index:1000"
      :dialog="showaddQ"
      @closeClicked="showaddQ = false"
    ></AddQ>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
// import moment from 'moment-timezone';
import LottieAnimation from "lottie-web-vue";

export default {
  name: "PatientDashboard",
  components: {
    AppointmentUpcoming: () =>
      import("@/view/pages/patient/comp/appointments/upcoming.vue"),
    NewAppointment: () =>
      import("@/view/pages/appointment/comp/NewAppointment.vue"),
    AddQ: () => import("@/view/pages/patient/comp/questions/quadd.vue"),
    LottieAnimation,
    //PulltoRefresh: () => import("@/view/pages/utilComponents/pulltorefresh.vue"),
    Feed: () => import("@/view/pages/social/Feed.vue")
  },
  data() {
    return {
      isComplete: true,
      userInfo: "",
      mdFile: {},
      reloadupcoming: 0,
      showadd: false,
      showaddQ: false,
      loadingStatus: true,
      loadingnext: true,
      nextapp: null,
      departments: null,
      dSelected: null,
      loadingfeed: true,
      search: "",
      feed: [],
      asseturl: process.env.VUE_APP_api_url + "assets/",
      offsetTop: 0,
      showAddBtn: true,
      addDialog: false,
      showsearch: false,
      showfeeddetails: false,
      selectedFeedItem: null,
      isAddopen: false
    };
  },
  watch: {
    offsetTop(news, olds) {
      if (news <= 0) {
        this.showAddBtn = true;
      } else if (olds < news) this.showAddBtn = false;
      else this.showAddBtn = true;
    }
  },
  methods: {
    openAddAppointment() {
      this.$refs.newApp.open();
      this.isAddopen = true;
    },
    likeaction(i) {
      this.$feed.likeAction(i);
      if (i.like) {
        i.like = false;
        i.likes--;
      } else {
        i.like = true;
        i.likes++;
      }
      this.$forceUpdate();
    },
    openfeeditem(i) {
      this.selectedFeedItem = i;
      this.showfeeddetails = true;
    },
    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
    },
    rotate() {
      if (this.$refs.addicon.$el.className.includes("addiconr")) {
        this.$refs.addicon.$el.className =
          "v-icon notranslate mdi mdi-plus theme--dark white--text";
      } else {
        this.$refs.addicon.$el.className =
          "v-icon notranslate mdi mdi-plus theme--dark white--text addiconr";
      }
    },
    shownextapptime(time) {
      //(nextapp[0].date_time + "Z")
      //                | moment("timezone", getTimeZone(), "from", "now")
      this.$moment.locale(this.$root.lang);
      return this.$moment(time + " Z")
        .tz(this.getTimeZone())
        .fromNow();
    },

    refreshaction() {
      // console.log("called refresh action");
      /*setTimeout(() => {
        this.$refs.refresh.closerefresh();
      }, 4000);*/
      this.getUpcomingAppointment();

      this.$feed.getFeedItems().then(data => {
        // console.log("data from feed", data);
        this.feed = data;
        this.$refs.refresh.closerefresh();
        this.$forceUpdate();
      });
    },
    getTimeZone() {
      return this.$util.getTimezone();
    },
    async getDepartments() {
      this.$department.getDepartments().then(data => {
        // console.log("data", data);
        this.departments = data;
        this.loadingStatus = false;
      });
    },

    async getUpcomingAppointment() {
      await ApiService.get(
        "items",
        "appointments?fields=id,status,video_room_id,rating,type,date,time,date_time,medical_file," +
          "doctor.doctor_id.first_name,doctor.doctor_id.last_name,doctor.doctor_id.avatar.data,doctor.doctor_id.id," +
          "rating&filter[medical_file.owner]=" +
          this.$user.getCurrentUser().id +
          "&filter[status][in]=progress,pending&sort=date_time&limit=1"
      )
        .then(({ data }) => {
          // console.log("upcoming next is", data.data);
          this.nextapp = data.data;
          this.loadingnext = false;
        })
        .catch(error => {
          this.error = error;
        });
    },
    getFeed() {
      this.$feed.getFeedItems().then(data => {
        this.loadingfeed = true;
        this.feed = data;
        console.log("feed", data);
        this.loadingfeed = false;
      });
    }
  },
  activated() {
    this.getUpcomingAppointment();
  },
  mounted() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo).id;
    }
    //this.getDepartments();
    this.getUpcomingAppointment();
    this.getFeed();
    this.$util.EventBus.$on("updateAppointmentstatus", data => {
      // console.log("this.nextapp", this.nextapp, "incoming update",data);
      this.nextapp = [];
      this.nextapp.push(data.data);
    });
  },
  computed: {
    feedByLang() {
      return this.feed
        .filter(item => {
          return (
            item.translation.findIndex(t => {
              return t.lang == this.$root.lang;
            }) != -1
          );
        })
        .map(item => {
          item.content = item.translation.find(trans => {
            return trans.lang == this.$root.lang;
          });
          return item;
        });
    }
  }
};
</script>

<style>
.addiconr {
  transform: rotate(45deg);
  transition-duration: 0.5s;
}
</style>
<style scoped>
/* .theme--light >>> .v-toolbar__content {
  padding-right: 0px;
  padding-left: 0px;
}
.theme--light >>> .v-input__append-inner {
  margin: 0px;
  margin-top: 0px !important;
  align-self: center;
}
.theme--light >>> .v-input__prepend-inner {
  margin: 0px;
  margin-top: 0px !important;
  align-self: center;
}
.theme--light >>> .v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0px 7px 0px 7px !important;
} */
</style>

<style lang="scss" scoped>
.skeleton-box {
  height: 140px;
  border-radius: 15px;
  overflow: hidden;
  background-color: #dddbdd;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
</style>
